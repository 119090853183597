<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Introduction</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      <span class="subhead">A</span> spring is a mechanical device that stores mechanical energy when it is compressed or stretched. When a force is applied to a spring, it deforms and the energy is stored in the spring. When the force is removed, the spring returns to its original shape and the stored energy is released.<br><br>
      Springs are typically made of metals and are used in in a variety of mechanical systems such as suspensions, mechanical clocks, and toys.<br><br>
      <div style="margin-top: 10px" />
      <v-layout align-center
                justify-center
                row
                wrap
      >
        <v-flex xs12
                sm8
                md8
                lg8
                xl8
                class="font-weight-light"
                :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <div class="font-weight-medium"
               :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
          >
            <span style="font-family:Oswald"> Force in a Spring: Hooke's Law</span>
          </div>
          <div style="margin-top: 20px" />

          You may have noticed that when a spring is subjected to an external force, it compresses or stretches, and its length changes. The deformation in the spring is related to the applied force.  This relationship is known as Hooke's law (named after british physicist Robert Hooke).<br><br>
          Hooke, while experimenting with springs, found that the force required to stretch or compress a spring is linearly proportional to the amount of extension or compression in the spring.
          Mathematically, Hooke's law can be expressed as:
          $$ F \propto x $$
          where \(x = l - l_0\) is the extension or compression of the spring (equal to deformed length minus original length).<br><br>
          Expressed as an equality (by introducing a proportionality constant \(k\)):
          $$ F = k (l- l_0)$$
          where \(F\) is the force applied on the spring, \(l\) is the deformed length and \(l_0\) is the original length of spring. The constant \(k\) in the equation is known as spring constant, and is a measure of the stiffness of the spring.
        </v-flex>
        <v-flex xs12
                sm4
                md4
                lg4
                xl4
        >
          <v-img class="imgC"
                 padding="0px"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Hooke.jpg"
                 contain
          />
          <br>
          <h5 style="text-align:center">
            English scientist and engineer Robert Hooke discovered the relationship between the deformation and forces in a spring.
          </h5>
        </v-flex>
      </v-layout>
      <div ref="ib" style="margin-top: 20px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Lesson Plan</span>
      </div>
      <div style="margin-top: 20px" />
      <div class="font-weight-light"
           :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        This module on springs consists of three lessons. Each lesson comes with a visually interactive illustrations that offers students a uniquely engaging and effective learning experience.
        <div style="margin-top: 10px" />
        <h5> Lesson 1 | Force & Energy in a Spring </h5>
        <div style="margin-top: 10px" />
        Learn about the force and stored energy in a spring.
        <div style="margin-top: 10px" />
        <h5> Lesson 2 | System of Springs </h5>
        <div style="margin-top: 10px" />
        Learn to calculate the effective stiffness of a system of springs connected in series or parallel.
        <div style="margin-top: 10px" />
        <h5> Lesson 2 | Spring-Mass System </h5>
        <div style="margin-top: 10px" />
        Learn about the oscillations of spring-mass systems.
      </div>
    </div>
    <div style="margin-top: 20px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          This is a visio-interactive demonstration of Hooke's experiment. Use <i class="plus ma-1" /> button to add weights and <i class="minus ma-1" /> button to remove weights.
          Observe how the deformation of the spring changes with applied load.
        </h5>
      </v-layout>
    </v-responsive>
    <div style="margin-top: 20px" />
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div style="margin-top: 10px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Let's get started</span>
      </div>
      <div ref="ic" style="margin-top: 20px" />
      Check out our library of meticulously crafted lessons on springs. These specially designed lessons use visuo-spatiality and interactivity to help students improve engagement and retain concepts.
      <div style="margin-top: 20px" />
      <v-container grid-list-xl text-xs-center>
        <v-layout
          align-center
          justify-center
          row
        >
          <v-flex v-for="topic in topics"
                  :key="topic.key"
                  xs12
                  sm12
                  md12
                  lg12
                  xl12
          >
            <subtopic-card
              :headline="topic.headline"
              :subheading="topic.subheading"
              :subsubheading="topic.subsubheading"
              :description="topic.description"
              :img="topic.img"
              :path="topic.path"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>
<script>
import Boxes from './hooke/Boxes.js'
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
  components: {
    SubtopicCard
  },
  data () {
    return {
      topics: [
      {
        headline: "Lesson 1",
        description: "",
        img:"/assets/Physics-6th.svg",
        path: "/physics/springs/energy"
      },
      { headline: "Lesson 2",
        description: "",
        img: "/assets/Physics-8th.svg",
        path: "/physics/springs/sos"
      },
      { headline: "Lesson 3",
        description: "",
        img: "/assets/Physics-10th.svg",
        path: "/physics/springs/springmass"
      },
/*      {
        headline: "Spring-Mass System",
        description: "Oscillations of a mass connected to a spring.",
        img: "/assets/Physics-12th.svg",
        path: "/physics/springs/springmass"
      }
  */
      ]
    }
  },
  created () {
     this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'Forces in Springs');
    this.$store.commit('navigation/changeMenu', 'Forces in Springs');
    let newTopics = [
      {title: 'Hooke\'s Law', img:'/assets/number-1.svg', action: () => this.$router.push({path: '/physics/springs/hooke'})},
      {title: 'Elastic Energy', img:'/assets/number-2.svg', action: () => this.$router.push({path: '/physics/springs/hooke'})},
      {title: 'System of Springs',img:'/assets/number-3.svg', action: () => this.$router.push({path: '/physics/springs/hooke'})},
      {title: 'Spring-Mass System', img:'/assets/number-4.svg', action: () => this.$router.push({path: '/physics/springs/springmass'})},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replacePhys', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();},
  metaInfo() {
    return{ title: 'Forces in Springs',
            titleTemplate: '%s | Learn interactively',
            meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'Balancing a chemical equation.'}
                  ]
          }
     },
}
</script>
