import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  placeRightText,
  placeLabel,
  placeLogo,
  drawSegment,
  writeMiddleText,
  placeTapeVert,
  drawPoint,
  alert
} from '../Utils';
const Boxes = {
    box1: function () {
//JXG.Options.layer['arrow'] =11;
      var brd = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox:[-2.,6., 8.,-4], axis:false,
        keepaspectratio:true, showNavigation:false, showCopyright:false, zoom:{enabled:false}, pan:{enabled:false}});
        brd.options.layer['image'] =2;
        brd.options.layer['point'] =5;
        brd.options.layer['line'] =4;
        brd.options.layer['curve'] =4;
        brd.options.layer['axis'] =1;
        brd.options.point.highlight=false;
        brd.options.curve.highlight=false;
        brd.options.image.highlight=false;
        brd.options.line.highlight=false;
        brd.options.point.showInfobox=false;
        placeLogo(brd);
        makeResponsive(brd);
        placeTitle(brd, 'Hooke\'s Experiment', '(Add weights to stretch the spring)');
//AXIS
      brd.create('axis', [[2, 0], [4, 0]],{name:'', fixed:true});
      brd.create('axis', [[2, 0], [2, 4]],{name:'', fixed:true});
      brd.create('text', [6.5, 0.1, 'Deflection(m)'],{anchorY:'bottom',cssStyle:'fontFamily:Oswald', fontSize:function(){return 22*brd.canvasWidth/800}, fixed:true});
      brd.create('text', [1.8, 4.5, 'Force (kgf)'], {rotate:90, anchorX:'right',cssStyle:'fontFamily:Oswald', fontSize:function(){return 22*brd.canvasWidth/800}, fixed:true});
//SLIDERS
      var k= placeSlider(brd, 3, -1, 20, 28, 40, 2, '&nbsp; k');
      k.setAttribute({snapWidth:1, unitLabel:' N/m'});
//CEILING
      var base =brd.create('polygon',[[-2, 2.5],[2,2.5],[2, 2.0],[-2,2.0]], {vertices:{visible:false}, withLines:false, fillColor:'black', fixed:true});
//Gravity
      placeGravity(brd, [-1, 0], [-1,-1], 'g');
      var hook =brd.create('point', [0.0, ()=>-jk*9.8/k.Value()], {name:'', face:'circle', size:()=>2*brd.canvasWidth/800, fillColor:'red', strokeColor:'red', shadow:false, fixed:true});
//
      var Pt1 = brd.create('point', [1.0, 2.0], {name:'', face:'circle', size:0, fillColor:'white', strokeColor:'black', visible:false, fixed:true});
//
      var Pt2= brd.create('point', [1.0, ()=>-jk*9.8/k.Value()], {name:'', face:'circle', size:0, fillColor:'white', strokeColor:'black', visible:false, fixed:true});
//
      var fung =brd.create('functiongraph', [function(x){return 0.25*Math.sin(8*Math.PI*(x-2)/(2-hook.Y()))}, 2, function(){return hook.Y();}],{visible:false, strokeColor:'red'});
//
      var trans =brd.create('transform', [1.57, 0, 0], {type:'rotate', size:0});
// SPRING
      var spring = brd.create('curve', [fung, trans],{strokeColor:'black', strokeWidth:()=>5-4*(1-k.Value()/40), name:'Spring', withLabel:false, fixed:true});
//tape
      var tape=placeTapeVert(brd, Pt1, Pt2, 'd');
      tape.setAttribute({point1:{face:'triangleup', size:4}, point2:{face:'triangledown', size:4}, unitsLabel:'m'});
//
      var jk =0;
//
      writeMiddleText(brd, 0, 2.75, ()=>jk+' kg').setAttribute({anchorY:'middle', color:'black'});
//
      var hinge =brd.create('point', [0.0, 2], {size:0, name:'', face:'circle', fillColor:'white', strokeWidth:2, strokeColor:'black', shadow:false, fixed:true});
//
      brd.create('image', ['/assets/hook.svg', [()=>-0.4*0.5, ()=>-0.3-jk*9.8/k.Value()], [0.4, 0.4]]);
//
      brd.create('image', ['/assets/1-kg.svg', [()=>-0.8*jk*0.5, ()=>-jk*0.88-jk*9.8/k.Value()], [()=>jk*0.8, ()=>(jk)*0.8]], {visible:()=>jk==1});
//
      brd.create('image', ['/assets/2-kg.svg', [()=>-0.5*jk*0.5, ()=>-jk*0.525-jk*9.8/k.Value()], [()=>jk*0.5, ()=>(jk)*0.5]], {visible:()=>jk==2});
//
      brd.create('image', ['/assets/3-kg.svg', [()=>-0.5*jk*0.5, ()=>-jk*0.485-jk*9.8/k.Value()], [()=>jk*0.5, ()=>(jk)*0.5]], {visible:()=>jk==3});
//
      brd.create('image', ['/assets/4-kg.svg', [()=>-0.5*jk*0.5, ()=>-jk*0.47-jk*9.8/k.Value()], [()=>jk*0.5, ()=>(jk)*0.5]], {visible:()=>jk==4});
//
/////////////////////////////////////////////////////////
//////////////////
// Buttons
      var msg1 =writeMiddleText(brd, 0., 3.5, 'Max weight reached!');
      msg1.setAttribute({color:'red', visible:false});
      //
      var msg2 =writeMiddleText(brd, 0., 3.5, 'No weights attached!');
      msg2.setAttribute({color:'red', visible:false});
      var add = brd.create('image', ['/assets/plus.svg', [-1., 2.5], [0.5, 0.5]],{fixed:true});
      var remove = brd.create('image', ['/assets/minuss.svg', [0.5, 2.5], [0.5, 0.5]],{fixed:true});
      add.on('down',function(){if(jk<4){jk+=1}else{jk=4; alert(brd, msg1);}});
      remove.on('down',function(){if(jk>0){jk-=1;}else{jk=0; alert(brd, msg2);}});
      //Plotting
      var pts1 = brd.create('point', [2,  0],{name:'',fillColor:'blue',strokeColor:'blue', fixed:true, trace:false});
      var pts = brd.create('point', [()=>2+jk*9.8/k.Value(), ()=>jk],{name:'',fixed:true, fillColor:'blue',strokeColor:'blue'});
      //
      var pts2 = brd.create('point', [()=>2+1*9.8/k.Value(), 1],{visible:()=>jk>=1, name:'',fixed:true, fillColor:'blue',strokeColor:'blue'});
      //
      var pts3 = brd.create('point', [()=>2+2*9.8/k.Value(), 2],{visible:()=>jk>=2, name:'',fixed:true, fillColor:'blue',strokeColor:'blue'});
      //
      var pts4 = brd.create('point', [()=>2+3*9.8/k.Value(), 3],{visible:()=>jk>=3, name:'',fixed:true, fillColor:'blue',strokeColor:'blue'});
      //
      var pts5 = brd.create('point', [()=>2+4*9.8/k.Value(), 4],{visible:()=>jk>=4, name:'', fixed:true, fillColor:'blue',strokeColor:'blue'});
      drawSegment(brd, pts1, pts).setAttribute({strokeColor:'blue'});
      },
}
export default Boxes;
